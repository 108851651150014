var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-btn',{attrs:{"color":_vm.$store.state.secondaryColor,"outlined":"","small":""},on:{"click":_vm.goBack}},[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v("mdi-arrow-left")]),_c('span',{staticClass:"caption"},[_vm._v(_vm._s(_vm.$t("back")))])],1)],1),_c('v-spacer')],1),_c('v-layout',{staticClass:"mb-2",attrs:{"row":"","wrap":"","justify-space-between":""}},[(!_vm.selected && _vm.ownersBusinesses.length)?_c('v-flex',{staticClass:"py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-select',{staticClass:"caption",attrs:{"dense":"","solo":"","label":_vm.$t('search business'),"item-text":"businessName","item-value":"id","items":_vm.ownersBusinesses},on:{"change":_vm.onSelectShop},model:{value:(_vm.selectedShop),callback:function ($$v) {_vm.selectedShop=$$v},expression:"selectedShop"}})],1):_vm._e(),_c('v-flex',{staticClass:"text-right py-1 px-1",attrs:{"xs12":"","sm4":"","md4":"","lg4":""}},[_c('v-text-field',{attrs:{"dense":"","solo":"","label":_vm.$t('search employees')},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer')],1),_c('v-layout',{attrs:{"row":"","wrap":"","justify-center":""}},[_c('v-flex',{attrs:{"xs12":"","sm12":"","md12":"","lg12":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"loading":_vm.loading,"items":_vm.employees,"search":_vm.search,"items-per-page":20},scopedSlots:_vm._u([{key:"item.avatar",fn:function(ref){
var item = ref.item;
return [_c('v-avatar',{attrs:{"size":"30"}},[_c('v-img',{staticStyle:{"cursor":"pointer"},attrs:{"src":item.avatar},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"width":"5","indeterminate":"","color":_vm.$store.state.primaryColor}})],1)]},proxy:true}],null,true)})],1)]}},{key:"item.businessId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getBusinessName(item.businessId))+" ")]}},{key:"item.names",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.names.first + " " + item.names.middle + " " + item.names.last)+" ")]}},{key:"item.shiftId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getShiftName(item.shiftId))+" ")]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"mx-1",attrs:{"x-small":"","left":"","outlined":"","color":_vm.$store.state.secondaryColor},on:{"click":function($event){return _vm.viewAttendance(item.id)}}},[_vm._v("Attendance details")])]}}],null,true)})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }